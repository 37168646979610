import get from "lodash/get";
// import { useEffect } from "react";
import type { FieldError, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "~/components/inputs/field-container";
import type { SelectAdditionalProps } from "~/components/inputs/select";
import { Select } from "~/components/inputs/select";
import type { RHFLabeledInputProps } from "~/types/forms/inputs";
type ReactHookFormProps<T> = SelectAdditionalProps & RHFLabeledInputProps<T>;
type ReactHookFormSelectProps<T> = ReactHookFormProps<T> & Omit<React.ComponentPropsWithoutRef<"select">, "name" | "value"> & {
  onChange?: (event?: React.ChangeEvent<HTMLSelectElement>) => void;
};
const RHFLabeledSelect = <T extends FieldValues,>({
  name,
  label,
  rules,
  containerClassName,
  required,
  description,
  ...props
}: ReactHookFormSelectProps<T>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext();
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  return <InputContainer readOnly={props.readOnly} name={name} label={label} required={required} error={error} className={containerClassName} description={description} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledSelect" data-sentry-source-file="select.tsx">
      <RHFSelect name={name} rules={rules} {...props} data-sentry-element="RHFSelect" data-sentry-source-file="select.tsx" />
    </InputContainer>;
};
const RHFSelect = <T extends FieldValues,>({
  name,
  rules,
  required,
  defaultOption,
  shouldUnregister,
  onChange,
  ...props
}: ReactHookFormSelectProps<T>) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = get(errors, name) as FieldError | undefined;
  const {
    field
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      required
    },
    shouldUnregister
  });
  return <Select isError={Boolean(error)} {...props} name={field.name} onChange={event => {
    if (event.target.value === "") {
      field.onChange(undefined);
    } else {
      field.onChange(event.target.value);
    }
    if (onChange) {
      onChange(event);
    }
  }} defaultOption={defaultOption ?? true} value={field.value ?? ""} ref={field.ref} onBlur={field.onBlur} data-sentry-element="Select" data-sentry-component="RHFSelect" data-sentry-source-file="select.tsx" />;
};
export { RHFSelect, RHFLabeledSelect };