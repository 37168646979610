import { isValid, parseISO } from "date-fns";
import { isString } from "lodash";

/**
 * Returns a date as string formated like "yyyy-MM-dd" or an empty string if you pass an ISO string to the value and the input will handle it
 *  */
export const formatDateInputValue = (date?: string | number | readonly string[]) =>
  date && isString(date) && isValid(parseISO(date)) ? (new Date(date).toISOString().split("T")[0] as string) : "";

/**
 * This returns you a string in ISO format or an empty string if date is invalid
 *  */
export const validateAndConvertToISODateString = (date: string) => (isValid(new Date(date)) ? new Date(date).toISOString() : "");
