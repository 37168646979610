import { ArrowPathIcon, CalendarIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import React, { forwardRef } from "react";
import { InputContainer } from "~/components/inputs/field-container";
import { emptyInputClassName } from "~/components/inputs/utils";
import { Typography } from "~/components/typography";
import { NO_ANSWER } from "~/constants/inputs";
import type { InputProps, LabeledInputProps, OmittedFieldsWhenReset, ResetForInputProps } from "~/types/forms/inputs";
import { formatDateInputValue, validateAndConvertToISODateString } from "~/utils/date";
type DatePickerProps = React.ComponentPropsWithRef<"input"> & InputProps;
type LabeledDatePickerProps = React.ComponentPropsWithRef<"input"> & LabeledInputProps;
type DatePickerWithResetProps = Omit<DatePickerProps, OmittedFieldsWhenReset> & ResetForInputProps;
type LabeledDatePickeResetProps = Omit<LabeledDatePickerProps, OmittedFieldsWhenReset> & ResetForInputProps;

/**
 * This component is meant to be the root of the date picker. It handles everything related to the date picker but the value and onChange props.
 * Those are handled in the RHFDatedPicker component and the DatePicker component respectively.
 * I did this because I can't pass the value already formatted to the RHF input component. It triggers an error because it needs to receive the native event, not a formatted string.
 */
export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(function DateInput({
  unstyled = false,
  isError,
  className,
  onChange,
  value,
  name,
  ...props
}, forwardedRef) {
  if (props.readOnly) {
    const hasValidValue = typeof value === "string" && value !== "";
    if (hasValidValue) {
      const stringValue = format(parseISO(value), "dd/MM/yyyy");
      return <div className="flex items-center gap-4">
          {!props.readOnly && <CalendarIcon className="h-5 w-5" />}
          <time dateTime={stringValue} className={className}>
            {stringValue}
          </time>
        </div>;
    } else {
      return <Typography.body className={clsx(emptyInputClassName, className)}>{NO_ANSWER}</Typography.body>;
    }
  }
  return <input type="date" id={name} ref={forwardedRef} className={clsx(className, !unstyled && [" rounded-[3px] border px-2 py-2 border-collapse focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border-neutral-300 disabled:bg-neutral-100 disabled:text-neutral-300 disabled:empty:text-neutral-600", "data-[locked=true]:border-neutral-300 data-[locked=true]:bg-neutral-50 data-[locked=true]:text-neutral-700", isError ? "border-red-500" : "border-neutral-400 focus:border-neutral-500", !!value ? "text-neutral-900" : "text-neutral-600 italic"])} value={formatDateInputValue(value)} data-empty={!value} onChange={event => {
    const validatedValue = validateAndConvertToISODateString(event.target.value);
    onChange?.({
      ...event,
      target: {
        ...event.target,
        value: validatedValue
      }
    });
  }} {...props} />;
});
export const LabeledDatePicker: React.FC<LabeledDatePickerProps> = ({
  containerClassName,
  label,
  description,
  name,
  error,
  required,
  ...props
}) => {
  return <InputContainer readOnly={props.readOnly} name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledDatePicker" data-sentry-source-file="date.tsx">
      <DatePicker isError={Boolean(error)} id={name} {...props} {...props.readOnly && {
      value: props.value
    }} data-sentry-element="DatePicker" data-sentry-source-file="date.tsx" />
    </InputContainer>;
};
export const DatePickerWithReset = forwardRef<HTMLInputElement, DatePickerWithResetProps>(function DateInput({
  handleReset,
  className,
  ...props
}, forwardedRef) {
  return <div className="flex w-full justify-between">
      <DatePicker ref={forwardedRef} className={clsx("peer w-full border border-neutral-400 pl-4 focus:border-neutral-500", props.value && "border-r-0", className)} {...props} />
      {props.value && <div className="flex select-none items-center border border-l-0 px-3 bg-white">
          <button type="button" className="h-full" onClick={handleReset}>
            <ArrowPathIcon className="h-5 w-5" />
          </button>
        </div>}
    </div>;
});
export const LabeledDatePickerWithReset: React.FC<LabeledDatePickeResetProps> = ({
  containerClassName,
  label,
  description,
  name,
  error,
  required,
  ...props
}) => {
  return <InputContainer readOnly name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledDatePickerWithReset" data-sentry-source-file="date.tsx">
      <DatePickerWithReset id={name} {...props} data-sentry-element="DatePickerWithReset" data-sentry-source-file="date.tsx" />
    </InputContainer>;
};