import clsx from "clsx";
import { type ComponentPropsWithoutRef, forwardRef, useLayoutEffect, useRef } from "react";
import { Typography } from "~/components/typography";
export const Checkbox = forwardRef<HTMLInputElement, React.ComponentPropsWithRef<"input"> & {
  isError?: boolean;
  rounded?: boolean;
}>(function Checkbox({
  isError = false,
  rounded = false,
  disabled = false,
  className,
  ...props
}, forwardedRef) {
  return <input disabled={disabled} type="checkbox" className={clsx("flex h-5 w-5 rounded-[3px] cursor-pointer items-center justify-center border border-aubergine ring-0 ring-offset-0 after:inline-block after:content-[''] after:checked:h-4 after:checked:w-4 after:checked:bg-aubergine  after:indeterminate:h-px after:indeterminate:w-2.5 after:indeterminate:bg-neutral-400 disabled:cursor-default", rounded ? "rounded-full after:rounded-full" : "after:rounded-[2px]", {
    "border-error after:checked:bg-error": isError
  }, {
    "border-aubergine after:checked:bg-aubergine": !isError && !disabled
  }, {
    "after:checked:bg-neutral-500 bg-neutral-200 border-neutral-500": disabled
  }, {
    "bg-transparent": !disabled
  }, className)} {...props} ref={forwardedRef} />;
});
export const CheckboxSelectAll = function CheckboxSelectAll({
  checked,
  indeterminate = false,
  ...props
}: ComponentPropsWithoutRef<"input"> & {
  indeterminate: boolean;
}) {
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  useLayoutEffect(() => {
    if (checkboxRef && "current" in checkboxRef && checkboxRef.current) {
      const {
        current
      } = checkboxRef;
      if (checked) {
        current.indeterminate = false;
        current.checked = true;
      } else if (indeterminate) {
        current.checked = false;
        current.indeterminate = true;
      } else {
        current.indeterminate = false;
        current.checked = false;
      }
    }
  }, [checkboxRef, checked, indeterminate]);
  return <Checkbox ref={checkboxRef} {...props} />;
};

// TODO: Delete once not used anymore
export const OldCheckboxSelectAll: React.FC<React.ComponentPropsWithoutRef<"input"> & {
  selectedAmount: number;
  totalAmount: number;
}> = ({
  selectedAmount,
  totalAmount,
  ...props
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const isIndeterminate = selectedAmount > 0 && selectedAmount < totalAmount;
  const isChecked = selectedAmount === totalAmount && totalAmount > 0;
  useLayoutEffect(() => {
    if (checkboxRef.current) {
      if (isChecked) {
        checkboxRef.current.indeterminate = false;
        checkboxRef.current.checked = true;
      } else if (isIndeterminate) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      } else {
        checkboxRef.current.indeterminate = false;
        checkboxRef.current.checked = false;
      }
    }
  }, [selectedAmount, isChecked, isIndeterminate]);
  return <Checkbox ref={checkboxRef} {...props} data-sentry-element="Checkbox" data-sentry-component="OldCheckboxSelectAll" data-sentry-source-file="checkbox.tsx" />;
};
export const CheckboxSelectAllWithText: React.FC<React.ComponentPropsWithRef<"input"> & {
  selectedAmount: number;
  totalAmount: number;
  text: string;
}> = ({
  text,
  className,
  ...props
}) => <label className={clsx("flex items-center space-x-2 transition-colors", props.selectedAmount === 0 ? "text-neutral-300" : "text-neutral-500", className)} data-sentry-component="CheckboxSelectAllWithText" data-sentry-source-file="checkbox.tsx">
    <OldCheckboxSelectAll {...props} data-sentry-element="OldCheckboxSelectAll" data-sentry-source-file="checkbox.tsx" />
    <Typography.body data-sentry-element="unknown" data-sentry-source-file="checkbox.tsx">{text}</Typography.body>
  </label>;