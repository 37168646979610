import { ArrowRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "~/components/button";
import { Dialog, Popup } from "~/components/dialog";
export const ConfirmDeletionDialog: React.FC<{
  onConfirm: () => void;
  isDeleting?: boolean;
}> = ({
  onConfirm,
  isDeleting
}) => {
  return <Popup showCloseButton={false} Icon={TrashIcon} title="Supprimer ce document ?" description="Ce document sera définitivement supprimé de la
      plateforme et ne sera, par conséquent, plus visible par 
      tout le monde." extra={<>
          <Button variant="plain" flavor="primary" className="flex items-center gap-x-3" onClick={onConfirm} disabled={isDeleting}>
            <TrashIcon className="h-4 w-4" />
            <span className="pt-0.5 font-normal">Supprimer ce document</span>
          </Button>
          <Dialog.Close asChild>
            <Button className="flex items-center gap-2" variant="inline" flavor="primary">
              <span>Ne pas supprimer ce document</span>
              <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </Dialog.Close>
        </>} data-sentry-element="Popup" data-sentry-component="ConfirmDeletionDialog" data-sentry-source-file="file.tsx" />;
};