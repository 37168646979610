import { ArrowRightIcon } from "@heroicons/react/24/outline";
import * as RadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";
import { forwardRef, useState } from "react";
import { emptyInputClassName } from "~/components/inputs/utils";
import { Typography } from "~/components/typography";
import type { MultiOption } from "~/types/forms/multi-option";
export type RadioRootProps = RadioGroup.RadioGroupProps;
export const RadioItem: React.FC<{
  indicatorClassName?: string;
  isError?: boolean;
  text?: string;
  isChecked?: boolean;
} & RadioGroup.RadioGroupItemProps> = ({
  indicatorClassName,
  disabled = false,
  isError = false,
  isChecked = false,
  text,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  return <div className={clsx("flex items-center space-x-2")} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} data-sentry-component="RadioItem" data-sentry-source-file="radio.tsx">
      <RadioGroup.Item id={props.value} className={clsx("flex h-5 w-5 rounded-full border", {
      "border-error": isError
    }, {
      "bg-neutral-200 border-neutral-500": disabled
    }, {
      "border-aubergine": !disabled && !isError
    })} {...props} data-sentry-element="unknown" data-sentry-source-file="radio.tsx">
        <RadioGroup.Indicator className={clsx(" flex h-full w-full items-center justify-center after:block after:h-4 after:w-4 after:rounded-full after:content-['']", {
        "after:bg-error": isError
      }, {
        "after:bg-neutral-500": disabled
      }, {
        "after:bg-aubergine ": !disabled && !isError
      }, indicatorClassName)} data-sentry-element="unknown" data-sentry-source-file="radio.tsx" />
      </RadioGroup.Item>
      {text && <label htmlFor={props.value} className={clsx("font-normal transition-colors", {
      "text-neutral-600": disabled,
      "text-neutral-900": !disabled && (isChecked || hover),
      "text-neutral-700": !disabled && !isChecked
    })}>
          {text}
        </label>}
    </div>;
};
export const RadioRoot = forwardRef(function RadioRoot({
  readOnly = false,
  ...props
}: RadioRootProps & {
  readOnly?: boolean;
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
  if (readOnly) {
    return <div className="flex items-center gap-2">
        <ArrowRightIcon className="text-neutral-900 h-4 w-4" />
        <Typography.body className={clsx(!props.value && emptyInputClassName, props.className)}>{props.value ? props.value : "/"}</Typography.body>
      </div>;
  }
  return <RadioGroup.Root ref={forwardedRef} {...props} />;
});
export const RadioInList = forwardRef(function RadioRoot({
  readOnly = false,
  items,
  isError,
  ...props
}: RadioRootProps & {
  readOnly?: boolean;
  label?: string;
  isError?: boolean;
  items: MultiOption[];
}, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
  if (readOnly) {
    const label = items.find(radioItem => radioItem.id === props.value)?.label;
    return <div className="flex items-center gap-2">
        <ArrowRightIcon className="text-neutral-900 h-4 w-4" />
        <Typography.body className={clsx(!label && emptyInputClassName, props.className)}>{label ? label : "/"}</Typography.body>
      </div>;
  }
  return <RadioGroup.Root ref={forwardedRef} {...props}>
      {items.map(radioItem => {
      const isChecked = props.value === radioItem.id;
      return <label key={radioItem.id} className="flex gap-2" htmlFor={radioItem.id}>
            <RadioItem isChecked={isChecked} value={radioItem.id} isError={isError} text={radioItem.label} disabled={props.disabled} />
          </label>;
    })}
    </RadioGroup.Root>;
});