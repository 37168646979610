import { ArrowUpOnSquareIcon, DocumentArrowDownIcon, PaperClipIcon, TrashIcon } from "@heroicons/react/24/outline";
import { TRPCClientError } from "@trpc/client";
import clsx from "clsx";
import keys from "lodash/keys";
import mime from "mime-types";
import type { Dispatch, SetStateAction } from "react";
import { createContext, forwardRef, useContext, useState } from "react";
import type { DropzoneOptions } from "react-dropzone";
import { ErrorCode as DropzoneErrorCode, useDropzone } from "react-dropzone";
import type { FileFragment } from "@monwbi/hasura";
import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { InputContainer } from "~/components/inputs/field-container";
import { ConfirmDeletionDialog } from "~/components/modals/file";
import { Typography } from "~/components/typography";
import { ERROR_RESPONSES } from "~/constants/errors";
import { FILE_ORIGIN } from "~/constants/file";
import { useSessionData } from "~/hooks/session";
import type { FileOriginType, FileStatusType } from "~/types/database";
import type { LabeledInputProps } from "~/types/forms/inputs";
import { downloadFile, uploadFile } from "~/utils/files";
import { Can } from "~/utils/permissions";
import { trpc } from "~/utils/trpc";
export type DropzoneAdditionalProps = Pick<DropzoneOptions, "accept" | "maxSize" | "multiple" | "disabled"> & {
  isError?: boolean;
};
type RejectedFileType = {
  file: File;
  errorMessage: string;
};
type DropZoneContextType = {
  readOnly: boolean;
  removeFromDBOnDelete: boolean;
  uploadedFiles: FileFragment[];
  loadingFiles: File[];
  rejectedFiles: RejectedFileType[];
  setLoadingFiles: Dispatch<SetStateAction<File[]>>;
  setRejectedFiles: Dispatch<SetStateAction<RejectedFileType[]>>;
  onUploadedFilesChange?: (files: FileFragment[]) => void;
};
const FilesContext = createContext<DropZoneContextType>({
  readOnly: false,
  removeFromDBOnDelete: false,
  uploadedFiles: [],
  loadingFiles: [],
  rejectedFiles: [],
  setLoadingFiles: () => null,
  setRejectedFiles: () => null,
  onUploadedFilesChange: () => null
});
export const CustomDropzoneErrorCodes = {
  ...DropzoneErrorCode,
  Duplicate: "duplicate",
  FailedUpload: "failed-upload"
} as const;

/**
 * Get the error message from a dropzone code, if it is an error code.
 *
 * @param code - The dropzone code.
 * @returns The error message.
 */
const getErrorFromDropzoneCode = ({
  errorCode,
  opts
}: {
  errorCode: string;
  opts?: {
    maxSize: number;
  };
}) => {
  switch (errorCode) {
    case CustomDropzoneErrorCodes.FileInvalidType:
      return ERROR_RESPONSES.files.invalid_format;
    case CustomDropzoneErrorCodes.FileTooLarge:
      return ERROR_RESPONSES.files.max_size(opts?.maxSize);
    case CustomDropzoneErrorCodes.Duplicate:
      return ERROR_RESPONSES.files.duplicate;
    case CustomDropzoneErrorCodes.FailedUpload:
      return ERROR_RESPONSES.files.failed_to_upload;
    default:
      return ERROR_RESPONSES.files.failed_to_upload;
  }
};
export interface DropzoneProps extends Omit<DropzoneOptions, "onDrop"> {
  readOnly?: boolean;
  removeFromDBOnDelete?: boolean;
  isError?: boolean;
  applicationId?: string;
  liquidationId?: string;
  pdfConfigId?: string;
  uploadToOrigin: FileOriginType;
  fileStatus?: FileStatusType;
  uploadedFiles?: FileFragment[];
  onUploadedFilesChange?: (files: FileFragment[]) => void;
}

/**
 * Composant Dropzone refactorisé pour être contrôlé par les props `uploadedFiles`.
 */
export const Dropzone = forwardRef<HTMLDivElement, React.PropsWithoutRef<DropzoneProps>>(function Dropzone({
  readOnly = false,
  removeFromDBOnDelete = false,
  applicationId,
  liquidationId,
  pdfConfigId,
  uploadToOrigin,
  fileStatus,
  uploadedFiles,
  onUploadedFilesChange,
  ...props
}, ref) {
  const [loadingFiles, setLoadingFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFileType[]>([]);
  const {
    data: sessionData
  } = useSessionData();
  return <FilesContext.Provider value={{
    readOnly,
    removeFromDBOnDelete,
    uploadedFiles: uploadedFiles ?? [],
    loadingFiles,
    rejectedFiles,
    setLoadingFiles,
    setRejectedFiles,
    onUploadedFilesChange
  }}>
      <div className="flex flex-col items-center space-y-4">
        <DroppedFiles />
        {!readOnly && <NativeDropzone {...props} disabled={props.disabled || (uploadedFiles ?? []).length > 0 && props.multiple === false} ref={ref} onDrop={async (acceptedFiles, fileRejections) => {
        setLoadingFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
        const newUploadedFiles: FileFragment[] = [];
        await Promise.all(acceptedFiles.map(async file => {
          try {
            const uploadedFile = await uploadFile({
              application_id: applicationId,
              liquidation_id: liquidationId,
              pdf_config_id: pdfConfigId,
              created_by: sessionData?.user.id,
              file_origin: uploadToOrigin,
              status: fileStatus,
              file
            });
            newUploadedFiles.push(uploadedFile);
            setLoadingFiles(prevFiles => prevFiles.filter(prevFile => prevFile.name !== file.name));
          } catch (err) {
            let message: string = CustomDropzoneErrorCodes.FailedUpload;
            if (err instanceof TRPCClientError) {
              message = err.message;
            }
            setLoadingFiles(prevFiles => prevFiles.filter(prevFile => prevFile.name !== file.name));
            setRejectedFiles(prevRejected => [...prevRejected, {
              file,
              errorMessage: getErrorFromDropzoneCode({
                errorCode: message
              })
            }]);
          }
        }));
        if (newUploadedFiles.length > 0) {
          onUploadedFilesChange?.([...(uploadedFiles ?? []), ...newUploadedFiles]);
        }
        const rejectedFilesFormatted = fileRejections.map(({
          file,
          errors
        }) => ({
          file,
          errorMessage: getErrorFromDropzoneCode({
            errorCode: errors[0].code,
            ...(!!props.maxSize && errors[0].code === DropzoneErrorCode.FileTooLarge && {
              opts: {
                maxSize: props.maxSize
              }
            })
          })
        }));
        if (rejectedFilesFormatted.length > 0) {
          setRejectedFiles(prevRejected => [...prevRejected, ...rejectedFilesFormatted]);
        }
      }} />}
      </div>
    </FilesContext.Provider>;
});

// Agnostic version of the Dropzone component
const NativeDropzone = forwardRef<HTMLDivElement, DropzoneOptions & {
  isError?: boolean;
}>(function DropzoneWithS3({
  isError = false,
  ...dropzoneOptions
}, ref) {
  // Get the accepted extensions from the accepted mime types

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone(dropzoneOptions);
  return <div className="w-full">
      <div {...getRootProps({
      ref,
      className: clsx("w-full py-2.5 px-6 text-center flex items-center gap-2 justify-center", {
        "border-error-80 text-error": isDragReject || isError,
        "border-success-80 text-success": isDragAccept,
        "border-neutral-700 text-neutral-700": !isDragActive && !isError,
        "border-neutral-100 text-neutral-200": dropzoneOptions.disabled
      }, dropzoneOptions.disabled ? "cursor-default" : "cursor-pointer")
    })} tabIndex={-1}>
        <input {...getInputProps()} />
        {/* FIXME: Using an icon makes the drop hover bug. Seems like the ref shits */}
        {/* <CloudArrowUpIcon className="h-5 w-6 flex-none" /> */}
        <ArrowUpOnSquareIcon className="h-5 w-6 flex-none" />
        importer un ou plusieurs fichiers
      </div>
      {dropzoneOptions.accept && <Typography.body className="text-sm mt-2 text-center" textColor="text-neutral-600">
          Types de fichiers autorisés :{" "}
          {keys(dropzoneOptions.accept).map(mime_type => `.${mime.extension(mime_type)}`).join(", ")}
        </Typography.body>}
    </div>;
});
const DroppedFiles: React.FC = () => {
  const {
    uploadedFiles,
    loadingFiles,
    rejectedFiles,
    readOnly
  } = useContext(FilesContext);
  const hasUploadedFiles = uploadedFiles.length > 0;
  const hasOnlyWbiGeneratedFiles = uploadedFiles.every(file => file.origin === FILE_ORIGIN.FROM_WBI_GENERATED);
  const noDocumentsMessage = readOnly ? "Aucun document disponible" : "Vous n'avez pas encore importé de document";
  return <aside className="w-full" data-sentry-component="DroppedFiles" data-sentry-source-file="dropzone.tsx">
      <ul>
        {hasUploadedFiles ? <>
            <UploadedFiles />
            {hasOnlyWbiGeneratedFiles && <Typography.body className="flex flex-col text-center text-neutral-500" textColor="text-neutral-500">
                {noDocumentsMessage}
              </Typography.body>}
          </> : <Typography.body className="flex flex-col text-center text-neutral-500" textColor="text-neutral-500">
            {noDocumentsMessage}
          </Typography.body>}
        {!readOnly && loadingFiles.length > 0 && <LoadingFiles />}
        {!readOnly && rejectedFiles.length > 0 && <RejectedFiles />}
      </ul>
    </aside>;
};
const FileLine: React.FC<React.ComponentPropsWithoutRef<"li">> = ({
  ...props
}) => {
  return <li className="flex items-center justify-center" {...props} data-sentry-component="FileLine" data-sentry-source-file="dropzone.tsx" />;
};
const RejectedFiles: React.FC = () => {
  const {
    rejectedFiles
  } = useContext(FilesContext);
  return <>
      {rejectedFiles.map(rejectedFile => <FileLine key={rejectedFile.file.name}>
          <RejectedFile rejectedFile={rejectedFile} />
        </FileLine>)}
    </>;
};
const RejectedFile: React.FC<React.PropsWithRef<{
  rejectedFile: RejectedFileType;
}>> = ({
  rejectedFile
}) => {
  const {
    setRejectedFiles
  } = useContext(FilesContext);
  const {
    file,
    errorMessage
  } = rejectedFile;
  return <>
      <div className="flex w-full justify-between gap-x-4">
        <div className="flex gap-x-4">
          <PaperClipIcon className="h-6 w-6 flex-none" data-sentry-element="PaperClipIcon" data-sentry-source-file="dropzone.tsx" />
          <Typography.error className="line-clamp-1" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">{file.name}</Typography.error>
        </div>
        <Typography.error className="line-clamp-1 text-right font-bold" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">{errorMessage}</Typography.error>
      </div>
      <div className="flex w-14 justify-end gap-1">
        <Button onClick={() => setRejectedFiles(prevRejected => prevRejected.filter(prevFile => prevFile.file.name !== file.name))} data-sentry-element="Button" data-sentry-source-file="dropzone.tsx">
          <TrashIcon className="h-6 w-6 text-black" data-sentry-element="TrashIcon" data-sentry-source-file="dropzone.tsx" />
        </Button>
      </div>
    </>;
};
const LoadingFiles: React.FC = () => {
  const {
    loadingFiles
  } = useContext(FilesContext);
  return <>
      {loadingFiles.map(loadingFile => <FileLine key={loadingFile.name}>
          <LoadingFile loadingFile={loadingFile} />
        </FileLine>)}
    </>;
};
const LoadingFile: React.FC<React.PropsWithoutRef<{
  loadingFile: File;
}>> = ({
  loadingFile
}) => {
  return <>
      <div className="flex w-full justify-between gap-4">
        <div className="flex gap-4">
          <PaperClipIcon className="h-6 w-6 flex-none" data-sentry-element="PaperClipIcon" data-sentry-source-file="dropzone.tsx" />
          <Typography.body className="line-clamp-1" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">{loadingFile.name}</Typography.body>
        </div>
        <Typography.body data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">loading...</Typography.body>
      </div>
      <div className="h-1 w-14" />
    </>;
};
const UploadedFiles: React.FC = () => {
  const {
    uploadedFiles
  } = useContext(FilesContext);
  const wbiGeneratedFiles = uploadedFiles.filter(file => file.origin === FILE_ORIGIN.FROM_WBI_GENERATED);
  const applicantGeneratedFiles = uploadedFiles.filter(file => file.origin !== FILE_ORIGIN.FROM_WBI_GENERATED);
  return <div className="flex flex-col gap-y-8" data-sentry-component="UploadedFiles" data-sentry-source-file="dropzone.tsx">
      {wbiGeneratedFiles.length > 0 && <div>
          <Typography.note textColor="text-neutral-600" fontWeight="500" className="pb-2">
            Template(s) proposé(s) pour cette question : {wbiGeneratedFiles.length}
          </Typography.note>
          {wbiGeneratedFiles.map(uploadedFile => <FileLine key={uploadedFile.id}>
              <div className="w-full flex justify-between items-center">
                <UploadedFile file={uploadedFile} />
              </div>
            </FileLine>)}
        </div>}

      <div>
        <Typography.note textColor="text-neutral-600" fontWeight="500" className="pb-2" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">
          Fichier(s) uploadé(s) par le demandeur : {applicantGeneratedFiles.length}
        </Typography.note>
        {applicantGeneratedFiles.map(uploadedFile => <FileLine key={uploadedFile.id}>
            <div className="w-full flex justify-between items-center">
              <UploadedFile file={uploadedFile} />
            </div>
          </FileLine>)}
      </div>
    </div>;
};
const UploadedFile: React.FC<React.PropsWithoutRef<{
  file: FileFragment;
}>> = ({
  file
}) => {
  const {
    readOnly,
    uploadedFiles,
    onUploadedFilesChange,
    removeFromDBOnDelete
  } = useContext(FilesContext);
  const {
    uri,
    id,
    name
  } = file;
  const getFileQuery = trpc.files.getById.useQuery({
    id
  }, {
    enabled: false
  });
  const deleteMutation = trpc.files.delete.useMutation();
  const disabled = getFileQuery.isFetching || deleteMutation.isLoading || deleteMutation.isSuccess;
  const isWbiGeneratedFile = file.origin === FILE_ORIGIN.FROM_WBI_GENERATED;
  const handleDelete = async () => {
    const {
      data
    } = await getFileQuery.refetch();
    const isFileInDB = !!data?.id;
    if (isFileInDB && removeFromDBOnDelete) {
      await deleteMutation.mutateAsync({
        uri
      });
    }
    onUploadedFilesChange?.(uploadedFiles.filter(f => f.id !== id));
  };
  return <>
      <button type="button" className="group w-80 flex gap-x-2" disabled={disabled} onClick={() => downloadFile(uri, name)}>
        <PaperClipIcon className="h-6 w-6 flex-none" data-sentry-element="PaperClipIcon" data-sentry-source-file="dropzone.tsx" />
        <Typography.body className="truncate text-left underline underline-offset-2 group-disabled:text-neutral-400 group-disabled:pointer-events-none" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">
          {name}
        </Typography.body>
        <Typography.body className="invisible" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">loading...</Typography.body>
      </button>

      <div className="flex w-7 justify-end gap-1">
        <Button onClick={() => downloadFile(uri, name)} disabled={disabled} className="group" data-sentry-element="Button" data-sentry-source-file="dropzone.tsx">
          <DocumentArrowDownIcon className="h-6 w-6 text-black group-disabled:hidden" data-sentry-element="DocumentArrowDownIcon" data-sentry-source-file="dropzone.tsx" />
        </Button>
        {!readOnly && !isWbiGeneratedFile && <Can I="delete" this={{
        ...file,
        __typename: "files"
      }}>
            <Dialog.Root>
              <ConfirmDeletionDialog isDeleting={deleteMutation.isLoading} onConfirm={handleDelete} />
              <Dialog.Trigger asChild>
                <Button disabled={disabled} className="group">
                  <TrashIcon className="h-6 w-6 text-black group-disabled:text-neutral-400" />
                </Button>
              </Dialog.Trigger>
            </Dialog.Root>
          </Can>}
      </div>
    </>;
};
type LabeledDropzoneProps = DropzoneProps & LabeledInputProps;
export const LabeledDropzone: React.FC<LabeledDropzoneProps> = ({
  label,
  description,
  name,
  error,
  required,
  containerClassName,
  ...rest
}) => {
  return <InputContainer label={label} description={description} name={name} error={error} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledDropzone" data-sentry-source-file="dropzone.tsx">
      <Dropzone isError={Boolean(error)} {...rest} data-sentry-element="Dropzone" data-sentry-source-file="dropzone.tsx" />
    </InputContainer>;
};