import type { FieldError, FieldPathValue, FieldValues, Path } from "react-hook-form";
import { Controller, get, useFormContext } from "react-hook-form";
import type { InputAttributes, NumberFormatValues, NumericFormatProps } from "react-number-format";
import { InputContainer } from "~/components/inputs/field-container";
import { NumericInput } from "~/components/inputs/numeric";
import type { RHFInputProps, RHFLabeledInputProps } from "~/types/forms/inputs";
type RHFNumericProps<T> = RHFInputProps<T> & NumericFormatProps<InputAttributes> & {
  className?: string;
};
type RHFLabeledNumericProps<T> = RHFLabeledInputProps<T> & RHFNumericProps<T>;
export const RHFLabeledNumeric = <T extends FieldValues,>({
  label,
  description,
  rules,
  name,
  containerClassName,
  required,
  ...props
}: RHFLabeledNumericProps<T>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext();
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  return <InputContainer readOnly={props.readOnly} label={label} description={description} name={name} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledNumeric" data-sentry-source-file="numeric.tsx">
      <RHFNumeric name={name} rules={rules} {...props} data-sentry-element="RHFNumeric" data-sentry-source-file="numeric.tsx" />
    </InputContainer>;
};
export const RHFNumeric = <T extends FieldValues,>({
  rules,
  name,
  required,
  ...props
}: RHFNumericProps<T>) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  return <Controller control={control} name={name} defaultValue={props.defaultValue as FieldPathValue<T, Path<T>>} rules={{
    ...rules,
    required
  }} render={({
    field
  }) => <NumericInput isError={Boolean(error)} onValueChange={(values: NumberFormatValues) => field.onChange(values.floatValue)} value={field.value} {...props} />} data-sentry-element="Controller" data-sentry-component="RHFNumeric" data-sentry-source-file="numeric.tsx" />;
};