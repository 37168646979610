import clsx from "clsx";

import type { Currency, MaxChar, NDecimals } from "~/types/dispositif";

export const textMaxLengthOptions: { id: MaxChar; label: string }[] = [
  { id: "unlimited", label: "Pas de limite" },
  { id: "420", label: `420 caractères` },
  { id: "840", label: `840 caractères` },
  { id: "1680", label: `1680 caractères` },
  { id: "3360", label: `3360 caractères` },
];

export const numberDecimalPrecisionOptions: { id: NDecimals; label: string }[] = [
  { id: "0", label: `1` },
  { id: "1", label: `0,1` },
  { id: "2", label: `0,01` },
  { id: "3", label: `0,001` },
];

export const currencyOptions: { id: Currency; label: string }[] = [
  { id: "eur", label: "€" },
  { id: "percentage", label: "%" },
];

export const NO_ANSWER = "Aucune réponse";

export const TEXT_INPUT_CLASSNAMES = clsx(
  "focus:border-aubergine-60 h-full rounded-sm text-neutral-800 focus:ring-0 focus:z-10 no-scrollbar placeholder:text-neutral-600 disabled:text-neutral-500 disabled:border-neutral-300 disabled:placeholder:text-neutral-500 border focus:outline-none disabled:cursor-not-allowed disabled:bg-purple-10 print:bg-transparent",
);

export const ibanFormatted = /\s/g;
