import { DevTool } from "@hookform/devtools";
import type { ComponentPropsWithoutRef } from "react";
import type { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import { FormProvider } from "react-hook-form";
type ReactHookFormProps<T extends FieldValues> = {
  methods: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>; /* ; defaultValues?: T */
} & Omit<ComponentPropsWithoutRef<"form">, "onSubmit">;

/**
 * This is a context that we can use to provide the defaultValues of the form to the RHFInputField.
 * This is useful when we want to use the defaultValues of the form to set the initial value of the input and the defaultValues has to be transformed.
 * Currently we don't use it because we use this context in the RHFInputField because we can use Controller instead to format default values and keep most of the current implementation.
 *
 * @example usage within a RHFInputField
 * const { defaultValues } = useFormDefaultValuesContext<T>();
 * const defaultValue = get(defaultValues, name);
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we need to use any here because we don't know the type of the defaultValues since it is based on the generic and we don't use the context in the component but the useFormDefaultValuesContext hook which is typed
// const FormContext = createContext<{ defaultValues: any } | null>(null);

// export function useFormDefaultValuesContext<T extends FieldValues>(): { defaultValues: T } {
//   const context = useContext(FormContext);

//   if (context === null) {
//     throw new Error("useFormContext must be used within a <Form />");
//   }

//   return { defaultValues: context.defaultValues };
// }

export const Form = <T extends FieldValues,>({
  methods,
  onSubmit,
  children,
  className,
  ...props
}: ReactHookFormProps<T>) => {
  return <FormProvider {...methods} data-sentry-element="FormProvider" data-sentry-component="Form" data-sentry-source-file="form.tsx">
      <form className={className} onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
        {/* uncomment to debug */}
        <DevTool control={methods.control} data-sentry-element="DevTool" data-sentry-source-file="form.tsx" />
      </form>
    </FormProvider>;
};