import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { forwardRef } from "react";
import type { InputAttributes, NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";
import { InputContainer } from "~/components/inputs/field-container";
import { emptyInputClassName } from "~/components/inputs/utils";
import type { InputProps, LabeledInputProps, OmittedFieldsWhenReset, ResetForInputProps } from "~/types/forms/inputs";
import { getNumericPlaceholder } from "~/utils/input";
type NumericInputProps = NumericFormatProps<InputAttributes> & InputProps;
type LabeledNumericInputProps = NumericFormatProps<InputAttributes> & LabeledInputProps;
type NumericInputWithResetProps = Omit<NumericInputProps, OmittedFieldsWhenReset> & ResetForInputProps;
type LabeledNumericInputWithResetProps = Omit<LabeledNumericInputProps, OmittedFieldsWhenReset> & ResetForInputProps;
export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(function NumericInput({
  isError,
  unstyled = false,
  readOnly,
  className,
  decimalScale = 3,
  decimalSeparator = ",",
  thousandSeparator = ".",
  placeholder,
  value,
  disabled,
  ...props
}, ref) {
  return <NumericFormat getInputRef={ref} readOnly={readOnly} className={clsx(readOnly ? [!value && emptyInputClassName, "border-none p-1"] : !unstyled && ["rounded-[3px] border px-2 py-2 w-full text-neutral-900 placeholder:text-neutral-600 placeholder:italic focus:border-neutral-500 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border-neutral-300 bg-transparent disabled:bg-transparent disabled:text-neutral-300 disabled:placeholder:text-neutral-300", "data-[locked=true]:border-neutral-300 data-[locked=true]:bg-neutral-50 data-[locked=true]:text-neutral-700", isError ? "border-red-500" : "border-grey-blue-80", disabled && "bg-neutral-100"], className)} allowLeadingZeros={false} decimalSeparator={decimalSeparator} decimalScale={decimalScale} defaultValue={props.defaultValue} thousandSeparator={thousandSeparator} placeholder={placeholder || getNumericPlaceholder(decimalScale, decimalSeparator)} value={readOnly && value == null ? "/" : value} {...props} />;
});
export const LabeledNumericInput: React.FC<LabeledNumericInputProps> = ({
  containerClassName,
  label,
  description,
  name,
  error,
  required,
  ...props
}) => {
  return <InputContainer readOnly={props.readOnly} name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledNumericInput" data-sentry-source-file="numeric.tsx">
      <NumericInput isError={Boolean(error)} id={name} {...props} {...props.readOnly && {
      value: props.value
    }} data-sentry-element="NumericInput" data-sentry-source-file="numeric.tsx" />
    </InputContainer>;
};
export const NumericInputWithReset = forwardRef<HTMLInputElement, NumericInputWithResetProps>(function NumericInputWithReset({
  handleReset,
  className,
  ...props
}, forwardedRef) {
  return <div className="flex w-full justify-between">
      <NumericInput ref={forwardedRef} {...props} className={clsx("peer w-full border border-r-0 border-neutral-400 pl-4  focus:border-neutral-500", className)} />
      <div className="flex select-none items-center border border-l-0 border-neutral-400 pr-2 peer-focus:border-neutral-500">
        {props.value && <button type="button" className="h-full" onClick={handleReset}>
            <ArrowUturnLeftIcon className="h-4 w-4" />
          </button>}
      </div>
    </div>;
});
export const LabeledNumericInputWithReset: React.FC<LabeledNumericInputWithResetProps> = ({
  containerClassName,
  label,
  description,
  name,
  error,
  required,
  ...props
}) => {
  return <InputContainer readOnly name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledNumericInputWithReset" data-sentry-source-file="numeric.tsx">
      <NumericInputWithReset className="w-full" id={name} {...props} data-sentry-element="NumericInputWithReset" data-sentry-source-file="numeric.tsx" />
    </InputContainer>;
};