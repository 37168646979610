import type { FieldError, FieldValues } from "react-hook-form";
import { get } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "~/components/inputs/field-container";
import type { RadioRootProps } from "~/components/inputs/radio";
import { RadioInList } from "~/components/inputs/radio";
import type { RHFLabeledInputProps } from "~/types/forms/inputs";
import type { MultiOption } from "~/types/forms/multi-option";
type ReactHookFormRadioProps<T> = RHFLabeledInputProps<T> & RadioRootProps & {
  items: MultiOption[];
};
export const RHFLabeledRadio = <T extends FieldValues,>({
  name,
  label,
  rules,
  required,
  containerClassName,
  ...props
}: ReactHookFormRadioProps<T>) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();

  /**
   * TODO: improve for useFieldArray.
   * When using a useFieldArray, the name it will try to reach will be for example `name`.
   * The real structure of a field in a useFieldArray is for example `folder.0.name`.
   * Using lodash get function on the full name will return the error.
   */
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  return <Controller control={control} name={name} rules={{
    ...rules,
    required
  }} render={({
    field: {
      onChange,
      onBlur,
      value,
      name,
      ref
    }
  }) => <InputContainer readOnly={props.readOnly} name={name} label={label} required={required} error={error} className={containerClassName}>
          <RadioInList id={name} onBlur={onBlur} value={value} name={name} onValueChange={onChange} ref={ref} isError={!!error} {...props} />
        </InputContainer>} data-sentry-element="Controller" data-sentry-component="RHFLabeledRadio" data-sentry-source-file="radio.tsx" />;
};