import clsx from "clsx";
import { get } from "lodash";
import type { ComponentProps, ComponentPropsWithoutRef } from "react";
import type { FieldError, FieldValues } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "~/components/inputs/field-container";
import { AutoResizeTextarea, Textarea } from "~/components/inputs/textarea";
import type { MaxChar } from "~/types/dispositif";
import type { RHFInputProps, RHFLabeledInputProps } from "~/types/forms/inputs";
type ReactHookFormTextAreaFieldProps<T> = RHFLabeledInputProps<T> & Omit<ComponentPropsWithoutRef<"textarea">, "name"> & {
  maxChar?: MaxChar;
};
const RHFLabeledTextarea = <T extends FieldValues,>({
  containerClassName,
  label,
  description,
  name,
  rules,
  required,
  maxChar,
  ...props
}: ReactHookFormTextAreaFieldProps<T>) => {
  const {
    register,
    control,
    formState: {
      errors
    },
    getValues
  } = useFormContext<T>();

  /**
   * TODO: improve for useFieldArray.
   * When using a useFieldArray, the name it will try to reach will be for exemple `name`.
   * The real structure of a field in a useFieldArray is for example `folder.0.name`.
   * Using lodash get function on the full name will return the error.
   */
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  const text = useWatch({
    control,
    name
  });
  const textLength = text ? text.length : 0;
  return <InputContainer readOnly={props.readOnly} name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledTextarea" data-sentry-source-file="textarea.tsx">
      <div className="relative">
        <Textarea isError={Boolean(error)} {...props} {...register(name, {
        ...rules,
        required,
        disabled: props.disabled
      })} {...props.readOnly && {
        value: getValues(name)
      }} {...maxChar && maxChar !== "unlimited" && {
        maxLength: parseInt(maxChar)
      }} data-sentry-element="Textarea" data-sentry-source-file="textarea.tsx" />
        {maxChar && maxChar !== "unlimited" ? <p className={clsx("absolute bottom-1 flex justify-end text-neutral-500 items-center px-2 py-1 border-t w-full", props.readOnly && "hidden")}>
            <span className="text-neutral-600 text-xs font-semibold leading-none">{textLength}</span>/
            <span className="text-xs font-semibold leading-none">{maxChar}</span>
          </p> : null}
      </div>
    </InputContainer>;
};
const RHFAutoResizeTextarea = <T extends FieldValues,>({
  name,
  placeholder,
  className,
  onBlur,
  rules = {},
  required,
  ...props
}: Omit<RHFInputProps<T>, "containerClassName"> & Omit<ComponentProps<typeof AutoResizeTextarea>, "isError"> & {
  forRequired?: boolean;
}) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext<T>();
  const error = get(errors, name) as FieldError | undefined;
  return <Controller control={control} name={name} rules={{
    ...rules,
    required
  }} render={({
    field
  }) => <AutoResizeTextarea id={field.name} onBlur={e => {
    field.onBlur();
    onBlur?.(e);
    field.onChange(e.target.value.trim());
  }} value={field.value} placeholder={placeholder} className={className} onChange={e => field.onChange(e.target.value)} ref={field.ref} {...props} isError={Boolean(error)} />} data-sentry-element="Controller" data-sentry-component="RHFAutoResizeTextarea" data-sentry-source-file="textarea.tsx" />;
};
export { RHFLabeledTextarea, RHFAutoResizeTextarea };