import clsx from "clsx";
import { throttle } from "lodash";
import type { ComponentProps } from "react";
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef } from "react";
import { emptyInputClassName } from "~/components/inputs/utils";
import { Typography } from "~/components/typography";
import { TEXT_INPUT_CLASSNAMES } from "~/constants/inputs";
import type { InputProps } from "~/types/forms/inputs";
export const Textarea = forwardRef<HTMLTextAreaElement, React.ComponentPropsWithRef<"textarea"> & InputProps>(function TextInput({
  unstyled = false,
  isError,
  className,
  readOnly = false,
  ...props
}, ref) {
  if (readOnly) {
    return <Typography.body className={clsx(!props.value && emptyInputClassName, className)}>{props.value ? props.value : "/"}</Typography.body>;
  }
  return <textarea id={props.name} ref={ref} className={clsx(!unstyled && [TEXT_INPUT_CLASSNAMES, isError ? "border-error" : "border-grey-blue-80"], "w-full placeholder:text-neutral-600 text-neutral-900 placeholder:italic rounded-[3px] disabled:bg-neutral-100", className)} {...props} />;
});
export const AutoResizeTextarea = forwardRef(function AutoResizeTextarea({
  className,
  forRequired = false,
  isError = false,
  ...props
}: ComponentProps<"textarea"> & {
  forRequired?: boolean;
  isError?: boolean;
}, ref?: React.ForwardedRef<HTMLTextAreaElement>) {
  const innerRef = useRef<HTMLTextAreaElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!);

  // Don't do this this only temporary since we will move to rich text editor for this
  const resizeTextArea = useRef(throttle(() => {
    if (!innerRef.current) return;
    innerRef.current.style.height = "auto";
    innerRef.current.style.height = innerRef.current.scrollHeight + "px";
  }, 300)).current;
  useLayoutEffect(resizeTextArea, [resizeTextArea, props.value]);
  const forRequiredAndReadOnlyMode = props.readOnly && !!props.value && forRequired;
  const forRequiredAndEditMode = (!props.readOnly || !props.value) && forRequired;
  return <div className="relative">
      {forRequiredAndReadOnlyMode ? <Typography.body data-testid="title-required" className={clsx("after:text-cherry-red absolute left-0 top-0 after:content-['_*']", className)}>
          {props.value}
        </Typography.body> : null}
      <div className={clsx(forRequiredAndEditMode && "after:text-cherry-red flex after:content-['_*']")}>
        <textarea className={clsx("flex no-scrollbar resize-none focus:outline-none focus:ring-0", isError && "border-error focus:border-dashed focus:border-error", forRequiredAndReadOnlyMode && "opacity-0", props.readOnly && !isError && "border-transparent", !props.value && forRequired && props.readOnly && "w-36", className)} {...props} rows={1} ref={innerRef} />
      </div>
    </div>;
});