import prettyBytes from "pretty-bytes";

const ERROR_RESPONSES = {
  text: {
    maxChar: (max: string) => `Ce champ ne doit pas dépasser ${max} caractères`,
  },
  numeric: {
    positive: `Ce champ doit être supérieur à 0`,
    decimals: (decimals: string) => `Ce champ doit avoir au maximum ${decimals} décimales`,
  },
  array: {
    min_1: "Veuillez fournir au moins une réponse",
    max_1: "Veuillez fournir au maximum une réponse",
  },
  required: "Ce champs est requis. Veuillez le renseigner.",
  invalid: "Ce champ est invalide",
  email: {
    invalid_format: "L’email doit respecter ce format : xxx@xxx.xxx",
  },
  date: {
    invalid_format: "Le format de la date est invalide",
  },
  password: {
    min_length: "Ce champ doit faire au minimum 6 caractères",
  },
  invalid_confirmation: "Ce champ doit être le même que celui au dessus",

  unchecked_condition: "Cette condition doit être acceptée pour pouvoir continuer",

  empty_memo: "Impossible de soumettre un mémo vide",
  files: {
    max_size: (size = 1000000) => `La taille du fichier dépasse la limite de ${prettyBytes(size)}`,
    duplicate: "Ce nom de fichier existe déjà",
    failed_to_upload: "Une erreur est survenue lors de l'envoi du fichier",
    invalid_format: "Le format du fichier est invalide",
  },
};

const ERROR_PAGE_CODES = {
  UNKNOWN: "unknown",
  MISSING_ROLES: "missing-roles",
} as const;

export { ERROR_RESPONSES, ERROR_PAGE_CODES };
