import clsx from "clsx";
import { find } from "lodash";
import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";
import { InputContainer } from "~/components/inputs/field-container";
import { Typography } from "~/components/typography";
import type { InputProps, LabeledInputProps } from "~/types/forms/inputs";
import type { MultiOption } from "~/types/forms/multi-option";
export type SelectOptionsGroup = {
  optgroupLabel: string;
  options: MultiOption[];
};
export type SelectAdditionalProps = {
  options?: MultiOption[];
  optionsGroups?: SelectOptionsGroup[];
  defaultOption?: boolean;
  flavor?: "outline" | "inline";
  readOnly?: boolean;
};
type SelectInputProps = React.ComponentPropsWithRef<"select"> & SelectAdditionalProps & InputProps;
type LabeledSelectInputProps = React.ComponentPropsWithRef<"select"> & SelectAdditionalProps & LabeledInputProps;
const SelectOption: React.FC<ComponentPropsWithoutRef<"option"> & MultiOption> = ({
  label,
  ...props
}) => {
  return <option {...props} value={props.id} data-sentry-component="SelectOption" data-sentry-source-file="select.tsx">
      {label}
    </option>;
};
const SelectOptions: React.FC<{
  options: MultiOption[];
}> = ({
  options
}) => {
  return <>
      {options.map(option => <SelectOption key={option.id} {...option} value={option.id} />)}
    </>;
};
const SelectOptionsGroups: React.FC<{
  optionsGroups: SelectOptionsGroup[];
}> = ({
  optionsGroups
}) => {
  return <>
      {optionsGroups.map(({
      optgroupLabel,
      options
    }) => <optgroup key={optgroupLabel} label={optgroupLabel}>
          {options.map(option => <SelectOption key={option.id} {...option} value={option.id} />)}
        </optgroup>)}
    </>;
};
export const Select = forwardRef<HTMLSelectElement, SelectInputProps>(function Select({
  options,
  optionsGroups,
  unstyled = false,
  isError,
  defaultOption = false,
  className,
  flavor = "outline",
  readOnly = false,
  ...props
}, ref) {
  if (readOnly) {
    return <Typography.body className={className}>{find(options, ["id", props.value])?.label ?? "/"}</Typography.body>;
  }
  return <select {...props} id={props.name} ref={ref} className={clsx(!unstyled && ["block py-2 pr-8 text-neutral-700 focus:border-neutral-500 focus:outline-none focus:ring-0 sm:text-sm", {
    "border bg-white pl-3": flavor === "outline",
    "border-neutral-400": flavor === "outline" && !isError,
    "border-red-500": flavor === "outline" && isError,
    "border-none focus:ring-0": flavor === "inline",
    "text-neutral-700": flavor === "inline" && !isError,
    "text-red-600": flavor === "inline" && isError
  }, !!props.value ? "text-neutral-700" : "text-neutral-400"], className)}>
      {defaultOption && <SelectOption className="text-neutral-400" id="" label="Sélectionner une option" />}
      {options && <SelectOptions options={options} />}
      {optionsGroups && <SelectOptionsGroups optionsGroups={optionsGroups} />}
    </select>;
});
export const LabeledSelect: React.FC<LabeledSelectInputProps> = ({
  name,
  label,
  containerClassName,
  error,
  required,
  description,
  ...props
}) => {
  return <InputContainer readOnly={props.readOnly} name={name} label={label} required={required} error={error} className={containerClassName} description={description} data-sentry-element="InputContainer" data-sentry-component="LabeledSelect" data-sentry-source-file="select.tsx">
      <Select isError={Boolean(error)} id={name} {...props} data-sentry-element="Select" data-sentry-source-file="select.tsx" />
    </InputContainer>;
};