import get from "lodash/get";
import { type ComponentPropsWithoutRef } from "react";
import type { FieldError, FieldValues } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "~/components/inputs/field-container";
import { TextInput } from "~/components/inputs/text";
import type { MaxChar } from "~/types/dispositif";
import type { RHFInputProps, RHFLabeledInputProps } from "~/types/forms/inputs";
export type RHFShortTextProps<T> = RHFInputProps<T> & Omit<ComponentPropsWithoutRef<typeof TextInput>, "name"> & {
  maxChar?: MaxChar;
};
type RHFLabeledShortTextProps<T> = RHFLabeledInputProps<T> & RHFShortTextProps<T>;
export const RHFLabeledShortText = <T extends FieldValues,>({
  containerClassName,
  label,
  description,
  name,
  rules = {},
  required,
  maxChar,
  ...props
}: RHFLabeledShortTextProps<T>) => {
  const {
    formState: {
      errors
    }
  } = useFormContext<T>();

  /**
   * TODO: improve for useFieldArray.
   * When using a useFieldArray, the name it will try to reach will be for exemple `name`.
   * The real structure of a field in a useFieldArray is for example `folder.0.name`.
   * Using lodash get function on the full name will return the error.
   */
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  return <InputContainer readOnly={props.readOnly} name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledShortText" data-sentry-source-file="text.tsx">
      <RHFShortText name={name} rules={rules} maxChar={maxChar} {...props} data-sentry-element="RHFShortText" data-sentry-source-file="text.tsx" />
    </InputContainer>;
};
export const RHFShortText = <T extends FieldValues,>({
  name,
  rules = {},
  required,
  maxChar,
  ...props
}: RHFShortTextProps<T>) => {
  const {
    register,
    formState: {
      errors
    },
    getValues
  } = useFormContext<T>();
  const error = get(errors, name) as FieldError | undefined;
  return <TextInput isError={Boolean(error)} {...props} {...register(name, {
    ...rules,
    required
  })} {...props.readOnly && {
    value: getValues(name)
  }} {...maxChar && maxChar !== "unlimited" && {
    maxLength: parseInt(maxChar)
  }} data-sentry-element="TextInput" data-sentry-component="RHFShortText" data-sentry-source-file="text.tsx" />;
};