import { XMarkIcon } from "@heroicons/react/24/outline";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import React from "react";
import { Typography } from "~/components/typography";
import type { HeroIcon } from "~/utils/types";
type ModalProps = DialogPrimitive.DialogContentProps & {
  showCloseButton?: boolean;
  errorModal?: boolean;
};
export const Dialog = {
  // This Dialog.Modal component simplifies creation of dialogs a bit but you are still free to use the underlying sub components
  // Keep in mind that you should always use a Dialog.Root alongside (and potentially a Dialog.Trigger)
  Modal: ({
    showCloseButton = true,
    errorModal = false,
    className,
    children,
    ...props
  }: ModalProps) => <Dialog.Portal>
      <Dialog.Overlay className="flex items-center justify-center px-14 py-24">
        <Dialog.Content errorModal={errorModal} className={clsx("relative flex flex-col", className)} {...props}>
          {children}
          {showCloseButton && <Dialog.Close className="absolute right-8 top-8" />}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>,
  Root: DialogPrimitive.Root,
  Trigger: DialogPrimitive.Trigger,
  Portal: DialogPrimitive.Portal,
  Content: React.forwardRef<HTMLDivElement, DialogPrimitive.DialogContentProps & {
    errorModal: boolean;
  }>(function DialogContent({
    className,
    errorModal,
    ...props
  }, forwardedRef) {
    return <DialogPrimitive.Content ref={forwardedRef} {...props}
    // Translate can lead to issue with non-retina screens if you want to avoid it we can make the overlay flex and center the content in the overlay instead -> https://github.com/radix-ui/website/issues/383
    className={clsx(errorModal ? "bg-error-20 border-error" : "bg-white border-blue-grey/80", className)} />;
  }),
  Close: React.forwardRef<HTMLButtonElement, DialogPrimitive.DialogCloseProps>(function DialogClose({
    children,
    ...props
  }, forwardedRef) {
    return <DialogPrimitive.Close ref={forwardedRef} {...props} aria-label="Close">
        {children ? children : <XMarkIcon className="h-6 w-6 text-black" />}
      </DialogPrimitive.Close>;
  }),
  Overlay: React.forwardRef<HTMLDivElement, DialogPrimitive.DialogOverlayProps>(function DialogOverlay({
    className,
    ...props
  }, forwardedRef) {
    return <DialogPrimitive.Overlay ref={forwardedRef} {...props} className={clsx("fixed inset-0 z-10 bg-slate-800/90", className)} />;
  }),
  Icon: ({
    Icon,
    className,
    ...props
  }: DialogPrimitive.DialogContentProps & {
    Icon: HeroIcon;
  }) => <span className={clsx("flex h-14 w-14 items-center justify-center rounded-full bg-blue-grey", className)} {...props}>
      <Icon className="h-7 text-white" />
    </span>,
  Title: React.forwardRef<HTMLDivElement, DialogPrimitive.DialogTitleProps>(function DialogTitle({
    className,
    ...props
  }, forwardedRef) {
    return <DialogPrimitive.Title ref={forwardedRef} {...props} className={clsx("text-center text-black text-lg font-medium tracking-[0.5px]", className)} />;
  }),
  Description: React.forwardRef<HTMLDivElement, DialogPrimitive.DialogDescriptionProps>(function DialogDescription({
    className,
    ...props
  }, forwardedRef) {
    return <DialogPrimitive.Description ref={forwardedRef} {...props} className={clsx("text-center text-base text-neutral-700", className)} />;
  })
};
export const DialogLayout: React.FC<{
  title: string;
  date?: string;
} & ModalProps> = ({
  title,
  date,
  children,
  ...props
}) => {
  return <Dialog.Modal className="overflow-y-auto py-16 px-20 h-full w-full flex flex-col !items-start space-y-10 !justify-start" {...props} data-sentry-element="unknown" data-sentry-component="DialogLayout" data-sentry-source-file="dialog.tsx">
      <div className="space-y-3">
        {date && <Typography.note>Date du {format(parseISO(date), "dd.MM.yyyy")}</Typography.note>}
        <Typography.h1 data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">{title}</Typography.h1>
      </div>
      {children}
    </Dialog.Modal>;
};
export const Popup: React.FC<{
  title: React.ReactNode;
  Icon: HeroIcon;
  description?: React.ReactNode;
  extra?: React.ReactNode;
} & Omit<ModalProps, "children">> = ({
  title,
  className,
  description,
  Icon,
  extra,
  errorModal,
  ...props
}) => {
  return <Dialog.Modal className={clsx("border items-center gap-7 max-w-2xl p-14", className)} {...props} data-sentry-element="unknown" data-sentry-component="Popup" data-sentry-source-file="dialog.tsx">
      {Icon && <Dialog.Icon Icon={Icon} className={clsx(errorModal && "bg-error")} />}
      <div className="flex flex-col gap-4">
        <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">{title}</Dialog.Title>
        {description && <Dialog.Description>{description}</Dialog.Description>}
      </div>
      {extra && <div className="flex flex-col items-center gap-4">{extra}</div>}
    </Dialog.Modal>;
};