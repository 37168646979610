import { ArrowPathIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { KeyOption } from "match-sorter";
import { matchSorter } from "match-sorter";
import { useState } from "react";
import { Badge } from "~/components/badge";
import { Checkbox } from "~/components/inputs/checkbox";
import { useComboboxSelection } from "~/components/inputs/combobox";
import { ComboBox } from "~/components/inputs/combobox";
import { InputContainer } from "~/components/inputs/field-container";
import { emptyInputClassName } from "~/components/inputs/utils";
import { Typography } from "~/components/typography";
import type { LabeledInputProps, OmittedFieldsWhenReset, ResetForInputProps } from "~/types/forms/inputs";
import type { MultiOption } from "~/types/forms/multi-option";
export type DropdownSelectProps<Option extends MultiOption> = {
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  options: Option[];
  keys: readonly KeyOption<Option>[];
  isError?: boolean;
  value: Option[];
  isMultipleChoice?: boolean;
  onChange: (value: Option[]) => void;
  className?: string;
  chevronClassName?: string;
};
type LabeledDropdownSelectProps<Option extends MultiOption> = LabeledInputProps & DropdownSelectProps<Option>;
type DropdownSelectWithResetProps<Option extends MultiOption> = Omit<DropdownSelectProps<Option>, OmittedFieldsWhenReset> & ResetForInputProps;
type LabeledDropdowntWithResetProps<Option extends MultiOption> = Omit<LabeledDropdownSelectProps<Option>, OmittedFieldsWhenReset> & ResetForInputProps;
export function DropdownSelect<Option extends MultiOption>({
  chevronClassName,
  ...props
}: DropdownSelectProps<Option>) {
  if (props.readOnly) {
    return <ReadOnlyDropdownSelect {...props} />;
  }
  return <InputDropdownSelect chevronClassName={chevronClassName} {...props} data-sentry-element="InputDropdownSelect" data-sentry-component="DropdownSelect" data-sentry-source-file="dropdown-select.tsx" />;
}
export function LabeledDropdownSelect<Option extends MultiOption>({
  containerClassName,
  label,
  description,
  error,
  name,
  required,
  ...props
}: LabeledDropdownSelectProps<Option>) {
  return <InputContainer readOnly={props.readOnly} name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledDropdownSelect" data-sentry-source-file="dropdown-select.tsx">
      <DropdownSelect isError={Boolean(error)} {...props} data-sentry-element="DropdownSelect" data-sentry-source-file="dropdown-select.tsx" />
    </InputContainer>;
}
export function DropdownSelectWithReset<Option extends MultiOption>({
  handleReset,
  chevronClassName,
  className,
  ...props
}: DropdownSelectWithResetProps<Option>) {
  return <div className="flex w-full justify-between" data-sentry-component="DropdownSelectWithReset" data-sentry-source-file="dropdown-select.tsx">
      <DropdownSelect className={clsx(props.value.length > 0 && "border-r-0", className)} {...props} chevronClassName={chevronClassName} data-sentry-element="DropdownSelect" data-sentry-source-file="dropdown-select.tsx" />
      {props.value.length > 0 && <div className="flex select-none items-center border border-l-0 px-3 bg-white">
          <button type="button" onClick={handleReset}>
            <ArrowPathIcon className="h-5 w-5" />
          </button>
        </div>}
    </div>;
}
export function LabeledDropdownSelectWithReset<Option extends MultiOption>({
  containerClassName,
  label,
  description,
  error,
  name,
  required,
  ...props
}: LabeledDropdowntWithResetProps<Option>) {
  return <InputContainer readOnly name={name} label={label} description={description} required={required} error={error} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledDropdownSelectWithReset" data-sentry-source-file="dropdown-select.tsx">
      <DropdownSelectWithReset {...props} data-sentry-element="DropdownSelectWithReset" data-sentry-source-file="dropdown-select.tsx" />
    </InputContainer>;
}
function InputDropdownSelect<Option extends MultiOption>({
  options,
  placeholder,
  keys = ["value"],
  isError,
  value,
  onChange,
  disabled,
  className,
  chevronClassName,
  isMultipleChoice = false,
  ...props
}: DropdownSelectProps<Option>) {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const filteredOptionItems = getOptionsItems({
    optionItems: options,
    inputValue,
    keys
  });
  const {
    selectedItems,
    setSelectedItems,
    addSelectedItem,
    getSelectedItemProps,
    removeSelectedItem,
    getDropdownProps
  } = useComboboxSelection({
    selectedItems: value,
    onValueChange: selectedOptions => onChange(selectedOptions),
    multiple: isMultipleChoice
  });
  return <ComboBox.Root isOpen={isOpen} onIsOpenChange={change => setIsOpen(change.isOpen)} disabled={disabled} isError={isError} filteredItems={filteredOptionItems} inputValue={inputValue} setInputValue={setInputValue} onSelectItem={item => {
    const element = selectedItems.find(selectedItem => selectedItem.id === item.id);
    if (element) {
      removeSelectedItem(element);
    } else {
      if (isMultipleChoice) {
        addSelectedItem(item as Option);
      } else {
        setSelectedItems([item as Option]);
      }
    }
  }} className={className} {...props} data-sentry-element="unknown" data-sentry-component="InputDropdownSelect" data-sentry-source-file="dropdown-select.tsx">
      <ComboBox.Trigger className="flex flex-wrap items-center gap-1 border-aubergine-20 border focus:border-aubergine-60 border-collapse pb-1 rounded-[3px]" chevronIconClassName={chevronClassName} data-sentry-element="unknown" data-sentry-source-file="dropdown-select.tsx">
        <ComboBox.Label className="text-black" data-sentry-element="unknown" data-sentry-source-file="dropdown-select.tsx">Choisir une option:</ComboBox.Label>
        {selectedItems.map((selectedItem, index) => <Badge key={index} onClose={disabled || !isMultipleChoice ? undefined : event => {
        event.stopPropagation();
        removeSelectedItem(selectedItem);
      }} {...getSelectedItemProps({
        selectedItem,
        index
      })} className={clsx(isMultipleChoice ? "rounded-full bg-sky-blue text-white" : "text-neutral-800")}>
            {selectedItem.label}
          </Badge>)}
        {/* Renders a badge with the number of items selected that are not visible on the screen  */}
        {!isOpen && selectedItems.length > 1 && <Badge className="bg-sky-blue text-white">+ {selectedItems.length - 1}</Badge>}
        <ComboBox.Input className="hidden" getDropdownProps={getDropdownProps} placeholder={selectedItems?.length === 0 ? placeholder : undefined} data-sentry-element="unknown" data-sentry-source-file="dropdown-select.tsx" />
      </ComboBox.Trigger>
      <ComboBox.Menu data-testid="dropdown-select" className="space-y-px py-2" data-sentry-element="unknown" data-sentry-source-file="dropdown-select.tsx">
        <ComboBox.OptionLabel className="px-3 py-1" data-sentry-element="unknown" data-sentry-source-file="dropdown-select.tsx" />
        {filteredOptionItems.map((option, index) => {
        const isSelected = selectedItems.some(selectedItem => selectedItem.id === option.id);
        return <ComboBox.Option key={index} index={index} item={option} isSelected={isSelected} className="flex items-center py-1 pl-2 pr-10">
              <Checkbox readOnly rounded={!isMultipleChoice} className="mr-2 peer border-aubergine" checked={isSelected} />
              <Typography.note textColor={isMultipleChoice ? "peer-checked:text-neutral-900 text-neutral-700" : "text-neutral-800"}>
                {option.label}
              </Typography.note>
            </ComboBox.Option>;
      })}
      </ComboBox.Menu>
    </ComboBox.Root>;
}
export function ReadOnlyDropdownSelect<Option extends MultiOption>({
  value,
  disabled
}: {
  value: Option[];
  disabled?: any;
}) {
  if (!value || value?.length === 0) return <Typography.body className={clsx(emptyInputClassName, "px-2 py-1 h-full flex items-center", disabled && "bg-purple-10 cursor-not-allowed")}>
        /
      </Typography.body>;
  return <div className={clsx("flex flex-wrap items-center gap-1 p-1 px-2", disabled && "bg-purple-10 cursor-not-allowed")} data-sentry-component="ReadOnlyDropdownSelect" data-sentry-source-file="dropdown-select.tsx">
      {value.map((selectedItem, index) => <Badge key={index} className="rounded-full bg-grey-blue-20">
          {selectedItem.label}
        </Badge>)}
    </div>;
}
export function getOptionsItems<Option extends MultiOption>({
  optionItems,
  inputValue,
  keys = ["id"]
}: {
  inputValue: string;
  optionItems: Option[];
  keys: readonly KeyOption<Option>[];
}) {
  const _optionItems = matchSorter(optionItems, inputValue, {
    keys,
    baseSort: (a, b) => a.index < b.index ? -1 : 1
  });
  if (inputValue === "") {
    return optionItems;
  }
  return _optionItems;
}